<template>
  <van-nav-bar
    :title="title"
  />
  <form action="/">
    <van-search v-model="searchKey" placeholder="输入手机号进行搜索" @clear="onClear" @search="onSearch" />
  </form>
  <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="getData"
    >
    <template v-for="(item,index) in list" :key="item.memberId">
      <div style="background:#fff;padding:15px;margin:15px 0 0 0;">
        <p style="margin:0 0 7px 0;">
          <small>会员id： {{ item.memberId }}</small>
        </p>
        <p style="margin:0 0 7px 0;">
          <small>手机号： {{ item.user.phone }}</small>
        </p>
        <p style="margin:0 0 7px 0;">
          <small>姓名： {{ item.name }}</small>
        </p>
        <p style="margin:0 0 7px 0;">
          <small>性别： {{ item.genderText }}</small>
        </p>
        <p style="margin:0 0 7px 0;">
          <small>生日： {{ item.birthday }}</small>
        </p>
        <p style="margin:0 0 7px 0;">
          <small>储值： {{ item.deposit }}</small>
        </p>
        <p style="margin:0 0 7px 0;">
          <small>积分： {{ item.points }}</small>
        </p>
        <p style="margin:0 0 7px 0;">
          <small>注册： {{ item.createTime }}</small>
        </p>
        <p style="margin:0 0 7px 0;">
          <small>备注：{{ item.remarks?item.remarks:'暂无备注' }}</small>
        </p>
        <p style="margin:0 0 7px 0;">
          <van-button type="primary" size="small" @click="openDialog(index)">修改会员备注</van-button>
        </p>
      </div>
    </template>
  </van-list>

  <van-dialog
    v-model:show="dialogShow"
    @confirm="dialogConfirm"
    show-cancel-button>
    <div class="inputbox">
      <van-field v-model="remark" placeholder="请输入会员备注" />
    </div>
  </van-dialog>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast } from 'vant'

export default {
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const state = reactive({
      page: 1,
      finished: false,
      loading: false,
      dialogShow: false,
      list: [],
      title: '',
      remark: '',
      userIndex: 0,
      form: {
        show: false,
        row: {}
      },
      user: {},
      row: {},
      searchKey: '',
      storeId: Cookies.get('storeId')
    })
    const onClear = () => {
      state.searchKey = ''
      reset()
    }
    const onSearch = (value) => {
      state.searchKey = value
      reset()
    }
    const openDialog = (index) => {
      const item = state.list[index]
      state.remark = item.remarks
      state.userIndex = index
      state.user = item
      state.dialogShow = true
    }
    const reset = () => {
      state.list = []
      state.page = 1
      state.finished = false
      state.loading = false
      getData()
    }
    const getData = () => {
      post('/member.list', {
        pageNum: state.page,
        storeId: state.storeId,
        phone: state.searchKey
      }).then(res => {
        state.list = [...state.list, ...res.data.content]
        state.loading = false
        state.finished = res.data.last
        state.page++
        state.title = '总共 ' + res.data.totalElements + ' 条记录'
      })
    }
    const dialogConfirm = () => {
      state.loading = true
      post('/member.update', {
        memberId: state.user.memberId,
        name: state.user.name,
        birthday: state.user.birthday,
        gender: state.user.gender,
        remarks: state.remark ? state.remark : ''
      }).then(res => {
        state.loading = false
        if (res.code === 0) {
          Toast.success()
          state.list[state.userIndex].remarks = state.remark
        } else {
          Toast(res.msg)
        }
      })
    }
    const init = () => {
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      getData,
      onSearch,
      onClear,
      openDialog,
      dialogConfirm
    }
  }
}
</script>

<style scoped>
body {
  background:#f9f9f9;
}

.inputbox{
  padding:8px;
}
</style>
